<template>
	<div :style="parsedStyle" :class="direction">
		<svg xmlns="http://www.w3.org/2000/svg" width="6.861" height="12" viewBox="0 0 6.861 12">
			<path d="M12.19,16.039,16.727,11.5a.854.854,0,0,1,1.211,0,.865.865,0,0,1,0,1.215L12.8,17.858a.856.856,0,0,1-1.183.025L6.438,12.717A.858.858,0,1,1,7.649,11.5Z"
				  transform="translate(-11.246 18.188) rotate(-90)"/>
		</svg>
	</div>
</template>

<script>
export default {
	name: "AppChevronIcon",
	props: {
		color: {type: String, default: '#0752A3' },
		direction: {type: String, default: 'right', validator(prop) { return ['left', 'right', 'up', 'down'].includes(prop) }},
		size: {type: Number, default: 13},
	},
	computed: {
		parsedStyle() {
			let finalDim = (this.size || 13) + 'px';
			let color = "--chevron-color: " + this.color + ";";
			return 'width:' + finalDim + '; height:' + finalDim + ';' + color;
		}
	}
}
</script>

<style lang="scss" scoped>
div {
	@include centered;

	&.up svg {
		transform: rotate(-90deg);
	}

	&.down svg {
		transform: rotate(90deg);
	}

	&.right svg {
		transform: rotate(0deg);
	}
}

svg {
	transition: transform .3s ease;
	transform: rotate(-180deg);
	height: 100%;
	width: 100%;

	path {
		fill: var(--chevron-color);
	}
}
</style>